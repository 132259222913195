import $ from 'jquery';


// Import only the Bootstrap components we need
//import { Util, Dropdown, Offcanvas, Popover } from 'bootstrap';
//import { Navbar } from 'bootstrap';

//Dependences

//import Swiper, {Navigation, Pagination} from 'swiper';


import '../scss/style.scss';
import {removecaritems} from './partials/remove-cart-item';
import {btnheaderbascet} from './partials/btnheaderbascet';


const scrollbarWidth = window.innerWidth - document.body.clientWidth
let headerheight = document.querySelector('.main-header').offsetHeight;
let topheaderheight = document.querySelector('.top-main-header').offsetHeight;
let middleheaderheight = document.querySelector('.middle-main-header').offsetHeight;
document.body.style.setProperty("--scrollbarWidth", `${scrollbarWidth}px`)
document.body.style.setProperty("--headerheight", `${headerheight}px`)
document.body.style.setProperty("--topheaderheight", `${topheaderheight}px`)
document.body.style.setProperty("--middleheaderheight", `${middleheaderheight}px`)


$(document).ready(function() {
  headerheight = document.querySelector('.main-header').offsetHeight;
  topheaderheight = document.querySelector('.top-main-header').offsetHeight;
  document.body.style.setProperty("--headerheight", `${headerheight}px`)
  document.body.style.setProperty("--topheaderheight", `${topheaderheight}px`)
  document.body.style.setProperty("--middleheaderheight", `${middleheaderheight}px`)
  $(window).on('resize', function(){
    headerheight = document.querySelector('.main-header').offsetHeight;
    topheaderheight = document.querySelector('.top-main-header').offsetHeight;
    document.body.style.setProperty("--headerheight", `${headerheight}px`)
    document.body.style.setProperty("--topheaderheight", `${topheaderheight}px`)
    document.body.style.setProperty("--middleheaderheight", `${middleheaderheight}px`)
  })
  removecaritems();
  btnheaderbascet();

  $('.hamburger.mobile-menu').on('click',function(e){
    //console.log('hamburger');
    e.preventDefault();
    $(this).toggleClass('is-active')
    $('.mobile-menu-wrap').toggleClass('show')
    $('.main-header').toggleClass('menu-show')
    //$('.main-header').toggleClass('open')
  })

  $('.megamenu-mobile').on('click',function(e){
    e.preventDefault();
    $(this).toggleClass('active');
    $('.main-header').toggleClass('megamenu-mobile-show')
    $('.bottom-main-header .navbar-collapse').animate({
      height: "toggle",
      opacity: "toggle"
    }, 500 );
  })


  $('.mobile-menu-wrap .has-children > .nav-link, .mobile-menu-wrap .has-children > .show-submenu').on('click',function(e){
    e.preventDefault();
    $(this).closest('.has-children').find('> .show-submenu').toggleClass('active');
    $(this).closest('.has-children').find('> .sub-menu').animate({
      height: "toggle",
      opacity: "toggle"
    }, 200 );
  })

  var scroll = 0;
  $(window).on('scroll',function(){
    let curscroll = $(window).scrollTop();
    if (curscroll > scroll){ 
      // downscroll code
      $('.main-header').removeClass('scrollup');
    } else {
      // upscroll code
      $('.main-header').addClass('scrollup');
    }
    if ((curscroll > 0) ) {
      $('.main-header').addClass('scroll');
    }else {
      $('.main-header').removeClass('scroll');
      $('.main-header').removeClass('scrollup');
        
    }
    scroll = curscroll;
  })


  $('.anime-scroll').on('click',function(e){
    e.preventDefault();
    let thishas = $(this).find('.nav-link').attr('href')
    $('html, body').animate({scrollTop: $(thishas).offset().top - 110}, 1000);
  })
  


  

});


function setCookieTrial() {
  var d = new Date();
  d.setTime(d.getTime() + (2*24*60*60*1000));
  var expires = "expires="+ d.toUTCString();
  document.cookie = "noticepopup=click;" + expires;
}


function setCookie(name,value,days) {
  var expires = "";
  if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days*24*60*60*1000));
      expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for(var i=0;i < ca.length;i++) {
      var c = ca[i];
      while (c.charAt(0)==' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
  }
  return null;
}
function eraseCookie(name) {
  document.cookie = name+'=; Max-Age=-99999999;';
}
